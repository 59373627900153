import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money, formatTime, formatDate} from '../../utility/Helper1';

import {footballActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";
import TransactionHolder from "../customComponents/TransactionHolder";

import {systemColor} from '../../utility/styles';
import { AiFillStar, AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";

import BetModalPlace from './BetModalPlace';
import DownModal from "../layouts/DownModal";


function FixtureDetail() {

    const params = useParams();
    const fixture_id = params.fixture_id


    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Loading history...');
    const footballData                      = useSelector(footballSelect);


    const [filterPanel, setFilterPanel]             = useState(false);

    const [fixtureId, setFixtureId]             = useState('');

    const [loading, setloading]             = useState(false);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    const [thisFixture_detail, setThisFixture_detail]     = useState(footballData?.fixtures?.filter((item, index) => item.fixture_id == fixture_id)?.[0]);
    
    const [betModal, setBetModal]          = React.useState(false);

    const finance                           = useSelector(homeScreenStatSelect);

    const dispatch                          = useDispatch();
    const systemColorManager                = systemColor()



    const { pathname } = useLocation();

    const isItSearchResultPage = pathname == '/fixture/result'?true:false

    const toggleFilterPannel = () => {
        setFilterPanel(!filterPanel)
    }

    const arrayOfHomeWins = thisFixture_detail?.exactScoreOdd?.filter(function(item, index) {
          return Number(item['home-scores']) > Number(item['away-scores']);
        });

    const arrayOfAwayWins = thisFixture_detail?.exactScoreOdd?.filter(function(item, index) {
          return Number(item['away-scores']) > Number(item['home-scores']);
        });

    const arrayOfDraws = thisFixture_detail?.exactScoreOdd?.filter(function(item, index) {
          return Number(item['away-scores']) == Number(item['home-scores']);
        });


    const oddIsClicked = (oddObj) => {
        setSelectedOddObj(oddObj)
        setBetModal(true)
    } 

    const showOdds = (arrayToUse) => {
        return arrayToUse?.map((item) => <div onClick = {() => oddIsClicked(item)} style={{borderRadius: 0, background: selectedOddObj?.id == item.id?'#90b203':'rgba(255, 255, 255, 0.9)', ...systemColorManager.txt_1, borderBottomWidth: 5, display: 'flex', padding: 4, marginBottom: 4, justifyContent: 'space-between'}} 
            className="cursor-pointer"
        >
            <span className="prem" style={{...systemColorManager.txt_1}}>{item.['home-scores']}:{item.['away-scores']}</span>
            <span className="prem" style={{...systemColorManager.txt_1}}>{Number(item?.['percentage-winning'])?.toFixed(1)}%</span>
        </div>)
    }







    const checkFixtureResult = () => {

        setLoadingText('Please wait...')
        setloading(true)

        dispatch(footballActions.onSubmitBet({fixtureId}, "football/result/filter", response => {
            
            setThisFixture_detail(response?.data?.filter_result?.[0])
            notify(response?.msg, response?.code)
            setloading(false)
            toggleFilterPannel()

        }))
    }




    return (

        <SiteWide loading={loading} overlayText={loadingText} title={`${thisFixture_detail?.teams_home_name ?? 'Home Team'} vs ${thisFixture_detail?.teams_away_name ?? 'Away Team'}  
            ${thisFixture_detail?.fixture_datetime?formatTime(thisFixture_detail?.fixture_datetime):''} 
            ${thisFixture_detail?.fixture_datetime?formatDate(thisFixture_detail?.fixture_datetime):''}`}>
           

            <div className="" style={{}}>

                <div>

                    <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                        

                        <div style={{backgroundSize: '100% 100%', backgroundPosition: 'center center'}}>

                           {/*<div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
                                

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flex: 1}}>
                                    <Images height={30} style={{}} source={thisFixture_detail?.league_logo} alt={thisFixture_detail?.league_name} />
                                    <span style={{marginLeft: 5, ...systemColorManager.txt_1}}>{thisFixture_detail?.league_name}</span>
                                    

                                </div>

                                {Number(thisFixture_detail?.['recommended-game']) === 1 && <span>
                                    <AiFillStar color={systemColorManager.txt_singly_1} size={15} />
                                </span>}

                            </div>*/}





                            {/*<div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flex: 1}}>
                                    {thisFixture_detail?.league_fixture_id != '' && <span style={{marginLeft: 5, ...systemColorManager.txt_1}}>Fixture Id: {thisFixture_detail?.fixture_id}</span>}
                                </div>
                            </div>*/}




                            <div className="flexify" style={{padding: 20}}>
                                
                                <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'flex-start', justifyContent: 'flex-start'}}>

                                    <div className="league-img-circle" style={{width: 100, height:100,...systemColorManager.btn_3}}>
                                        <Images height={80} style={{}} source={thisFixture_detail?.teams_home_logo} alt={thisFixture_detail?.teams_home_name} />
                                    </div>

                                    <div className="soccerfont" style={{marginTop: 45, ...systemColorManager.txt_1}}>{thisFixture_detail?.teams_home_name}</div>
                                    

                                </div>

                                <div className="text-center">
                                    <div className="prem" style={{fontSize: 30, ...systemColorManager.txt_1}}>
                                        {thisFixture_detail?.goals_home}:{thisFixture_detail?.goals_away}
                                    </div>

                                    <span className="soccerfont">{thisFixture_detail?.fixture_status_short}</span>

                                </div>

                                 <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'flex-end', justifyContent: 'flex-end'}}>

                                    <div className="league-img-circle" style={{width: 100, height:100,...systemColorManager.btn_3}}>
                                        <Images height={80} style={{}} source={thisFixture_detail?.teams_away_logo} alt={thisFixture_detail?.teams_away_name} />
                                    </div>

                                    <div className="soccerfont" style={{marginTop: 45, ...systemColorManager.txt_1}}>{thisFixture_detail?.teams_away_name}</div>

                                </div>

                            </div>

                        </div>


                        {(thisFixture_detail?.fixture_status_short ?? '') != 'NS' && <div className="flexify" style={{}}>
                            
                            <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>

                               <div style={{...systemColorManager.txt_1}}>
                                    <div>H1</div>
                                    <div>{thisFixture_detail?.score_halftime_home}</div>
                                    <div>{thisFixture_detail?.score_fulltime_home}</div>
                                </div>

                            </div>

                            <div className="text-center" style={{width: '50%'}}>
                                
                            </div>

                             <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>

                                <div style={{...systemColorManager.txt_1}}>
                                    <div>H2</div>
                                    <div>{thisFixture_detail?.score_halftime_away}</div>
                                    <div>{thisFixture_detail?.score_fulltime_away}</div>
                                </div>
                            </div>

                        </div>}


                    </div>




                </div>















                {isItSearchResultPage && <div style={{}}>
                    <button onClick={toggleFilterPannel} style={{borderRadius: 0, position: "fixed", bottom: "64px", left: "0", width: "100%",...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">Filter</button>
                </div>}


                <h2 className="prem">Select Odd</h2>

                {!isItSearchResultPage && <div style={{marginTop: 30}}>



                    {/*<div className="row">
                       
                        <div className="col-4">
                            <button className="prem btn-block" style={{borderRadius: 10, backgroundColor: 'transparent', ...systemColorManager.txt_3, ...systemColorManager.borders_color_2, marginBottom: 10}}>Home</button>
                        </div>


                        <div className="col-4">
                            <button className="prem btn-block" style={{borderRadius: 10, backgroundColor: 'transparent', ...systemColorManager.txt_3, ...systemColorManager.borders_color_2, marginBottom: 10}}>Draw</button>
                        </div>

                        <div className="col-4">
                            <button className="prem btn-block" style={{borderRadius: 10, backgroundColor: 'transparent', ...systemColorManager.txt_3, ...systemColorManager.borders_color_2, marginBottom: 10}}>Away</button>
                        </div>


                    </div>*/}


                    <div className="row">
                       
                        <div className="col-4">

                            {showOdds(arrayOfHomeWins)}

                        </div>


                        <div className="col-4">

                            {showOdds(arrayOfDraws)}

                        </div>

                        <div className="col-4">

                            {showOdds(arrayOfAwayWins)}

                        </div>

                    </div>


                    


                </div>}


            </div>
                





            {isItSearchResultPage && <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={filterPanel} onClose={toggleFilterPannel}>
                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="" style={{maxHeight: 'auto', width: '90%'}}>

                        


                        <form>

                            <div>

                                <div>
                                    <div className="single-input-wrap">

                                        <span className="new-input-label" style={{...systemColorManager.txt_1}}>Fixture Id</span>
                                        <input placeholder={"Fixture Id"} onChange = {e => setFixtureId(e.target.value) } required="" className="form-field form-control" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}} type="text" />
                                    
                                    </div>
                                </div>


                            </div>

                            <div>
                                <button onClick={checkFixtureResult} type="submit" disabled={loading} className="btn mt-40 btn-block" style={{...systemColorManager.btn_3}}>
                                Submit </button>
                            </div>
                            
                        </form>





                        <div style={{...systemColorManager.txt_1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <span style={{cursor: 'pointer', paddingTop: 4, paddingBottom: 4, }} onClick={toggleFilterPannel}>Close</span>
                        </div>


                    </div>
                </div>

            </DownModal>}
            




            {!isItSearchResultPage && <BetModalPlace setBettingInAction = {setloading} setBettingInActionText={setLoadingText} betModal={betModal} setBetModal={setBetModal} oddObjSelected = {selectedOddObj} />}




            

        </SiteWide>
    );
}

export default FixtureDetail;