import React, {Component, useState} from "react";
import {WebLink, Images} from "../customComponents";
// import { ToastContainer } from 'react-toastify';

import {useHistory} from "react-router-dom";
import MobileBreaker from "./MobileBreaker";
import {systemColor} from '../../utility/styles';

import { RiMenuLine } from "react-icons/ri";
import LoadingOverlay from 'react-loading-overlay';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';


function Header(props) {
    
        const [uploadingAvatar, setUploadingAvatar]           = useState(false);
        const hiddenFileInput = React.useRef(null);

        const systemColorManager = systemColor()
        const dispatch = useDispatch();

        const history = useHistory();
        const userData = useSelector(userSelect);
        const site_settings = useSelector(allRemoteSettingsSelect);

        const loadHome = () => {
            // if(history.goBack.length > 0){
            //     history.goBack() ?? history.push('/')
            // }else{
            //     history.push('/')
            // }

            history.goBack()
        }


        const progClickFileUpload = () => {
            if(!userData?.image){ history.push('/signin'); return;}
            hiddenFileInput.current.click();
        }


        const uploadAvatar = async (file) => {

            // instantUploadPreview(file, 'avatarPlace');
            setUploadingAvatar(true)

            dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                    setUploadingAvatar(false)
                }),
            )
      
        }

 


        return <div className="" style={{position: 'fixed', width: '100%', zIndex: 999999999, top: 0, left: 0}}>
                    
                    <MobileBreaker>
                        
                        <div className="header-area" style={{backgroundColor: '#fe3867'}}>
                            <div className="container">
                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="col-sm-3 col-3">
                                        <h5 className="text-white" style={{fontSize: 12, ...systemColorManager.txt_2}}>{userData?.username ?? ''}</h5>
                                    </div>
                                    <div className="col-sm-6 col-6 text-center">
                                        <WebLink to={'/'} className="logo">
                                            <Images style={{ height: '50px' }} src={(site_settings?.site_settings?.logo)} alt="logo" />
                                        </WebLink>
                                    </div>
                                    <div className="col-sm-3 col-3 text-right">

                                    <LoadingOverlay
                                        active={uploadingAvatar}
                                        spinner
                                    >
                                        <Images style={{width: 30, height: 30, borderRadius: '50%'}} id="avatarPlace" title="Click to upload" className="cursor-pointer im" onClick={progClickFileUpload} width="60px" source={((userData?.image ?? null) != null)?userData?.image:require('../../images/athlete1.png')} alt="avatar" />
                                        <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>

                                    </LoadingOverlay>


                                    </div>
                                </div>

                            </div>
                        </div>






                    </MobileBreaker>

                </div>


}

export default Header;