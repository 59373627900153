import React, {Component, useState, useEffect} from "react";
import SelectCurrency from "../customComponents/SelectCurrency";
import { useSelector, useDispatch } from 'react-redux';

import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import DownModal from "../layouts/DownModal";
import {systemColor} from '../../utility/styles';

import {money, notify} from '../../utility/Helper1';
import { AiOutlineWallet } from "react-icons/ai";
import {footballActions} from '../../redux/actions';


export default function BetModalPlace(props){

    const {betModal, setBetModal, oddObjSelected, setBettingInAction, setBettingInActionText} = props

    const [loading, setloading]             = useState(false);
    const finance                           = useSelector(homeScreenStatSelect);
    const [amount, setAmount]               = useState('');

    const [currencySelected, setCurrencySelected]   = useState(finance?.[0] ?? null);
    const [currencyModal, setCurrencyModal]   = useState(false);
    const systemColorManager = systemColor()
    const dispatch = useDispatch();


    useEffect(() => {
        setCurrencySelected(currencySelected ?? finance?.[0]);
    }, [finance])


    const toggleBetPanel = () => {
        setBetModal(false)
    }


    const toggleCurrencyModal = () => {
        setCurrencyModal(!currencyModal)
    }


    const updateCurrencySelected = (newCurObj) => {
        setCurrencySelected(newCurObj)
    }



    const doBetRequest = (e) => {

        e.preventDefault()
        if(loading){ return;}

        setloading(true)
        setBettingInAction(true)
        setBettingInActionText('Placing Bet')


        dispatch(footballActions.onSubmitBet({amount, currency: currencySelected.type, betId: oddObjSelected.id}, "football/bet", response => {

            if(response.code == 1){
                setAmount('')
                toggleBetPanel()
            }
            
            notify(response.msg, response.code)
            setloading(false)
            setBettingInAction(false)
            setBettingInActionText('')

            
            
        }))


    }

    const amountSuggestion = [5, 10, 15, 20, 50, 100, 200, 500];


    const thisCurIsSelected = finance?.filter((item, index) => item.type == currencySelected?.type)?.[0]


    const updateAmount = (e) => {
        if(isNaN(e.target.value)){ return; }
        setAmount(e.target.value) 
    }
    return (<>


                

                    <div className="" style={{maxHeight: 'auto', width: '100%'}}>

                        {/*<div style={{marginBottom: 40}}>
                            <button className="btn btn-info flexify" style={{color: '#000', borderRadius: 30}} onClick={toggleCurrencyModal}>
                                <AiOutlineWallet />
                                <div>{thisCurIsSelected?.name} - {money(thisCurIsSelected?.balance, thisCurIsSelected?.type)}</div>
                            </button>
                        </div>*/}


                        <form onSubmit={doBetRequest} style={{marginTop: 20}}>
                            
                            <h2 className="prem">Enter Bet Amount</h2>

                            <div class="ingle-input-wrap">

                                <div className="" style={{display: 'flex', flexDirection: 'row'}}>
                                    <span className="new-input-label" style={{flex: 1, ...systemColorManager.txt_1}}>Bet Amount</span>
                                    {amount !='' && <span className="new-input-label" style={{...systemColorManager.txt_1}}>Est. winning: {money((amount * (Number(oddObjSelected?.['percentage-winning'] ?? 0)/100)), thisCurIsSelected?.type)}</span>}
                                </div>

                                <input required value={amount} placeholder="Bet Amount" onChange = {updateAmount} required="" className="form-field form-control" type="text" style={{...systemColorManager.bg_3}}/>
                            
                            </div>



                            <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_1}}>
                                Submit
                            </button>

                        </form>


                    </div>
                




            <SelectCurrency selectedObj={currencySelected} curArrayToLoop={finance} isOpen={currencyModal} closeCurrencyModal={toggleCurrencyModal} onSelectCurrency={updateCurrencySelected} />

        </>)


}