import React, {useState, useEffect, useRef} from "react";
import ReactPaginate from 'react-paginate';
import './index.css';




	
export default function PaginatedItems({ itemsPerPage, arrayOfDataToDisplay, Template }) {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);



    useEffect(() => {
    	if(arrayOfDataToDisplay.length > 0){
	    	let items = [...arrayOfDataToDisplay];

		    // Fetch items from another resources.
		    const endOffset = itemOffset + itemsPerPage;
		    setCurrentItems(items.slice(itemOffset, endOffset));
		    setPageCount(Math.ceil(items.length / itemsPerPage));
		}
	  }, [arrayOfDataToDisplay, itemOffset, itemsPerPage]);




	  // Invoke when user click to request another page.
	  const handlePageClick = (event) => {
	    const newOffset = (event.selected * itemsPerPage) % arrayOfDataToDisplay.length;
	    
	    window.scrollTo(0, 0);

	    setItemOffset(newOffset);
	  };

  	return (
	    <>
	      	<Template currentItems={currentItems} />
		    
		    <div style={{textAlign: 'center'}} className="text-center">
			    <ReactPaginate
			        nextLabel=">"
			        onPageChange={handlePageClick}
			        pageRangeDisplayed={3}
			        marginPagesDisplayed={2}
			        pageCount={pageCount}
			        previousLabel="<"
			        pageClassName="page-item"
			        pageLinkClassName="page-link"
			        previousClassName="page-item"
			        previousLinkClassName="page-link"
			        nextClassName="page-item"
			        nextLinkClassName="page-link"
			        breakLabel="..."
			        breakClassName="page-item"
			        breakLinkClassName="page-link"
			        containerClassName="pagination"
			        activeClassName="activo"
			        renderOnZeroPageCount={null}
      />

			</div>
	    </>
  	);


}