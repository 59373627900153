import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import QRCode from "react-qr-code";
import {systemColor} from '../../utility/styles';
import parse from 'html-react-parser';
import DownModal from "../layouts/DownModal";


function RefLink({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const systemColorManager = systemColor()
    
    const [selectedLevel, setSelectedLevel]   = useState(1);
    const [invitationCodeModal, setInvitationCodeModal]   = useState(false);

    const dispatch = useDispatch();

    const copyRefLink = () => {
        notify('Referral Link Copied', 1)
    }


    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.site_url+'/#/signup?ref='+ myRefCode

    
    const toggleInviteModal = () => {
        setInvitationCodeModal(!invitationCodeModal)
    }


    const copyThis = (itemToCopy) => {
        copy(itemToCopy)
        notify('Yoyr referral code has been copied', 1)
    }

    return (
        <SiteWide overlayBg title={`Invite Friends`}>
                
            




                {/*<div className="" style={{ overflowX: "scroll", marginTop: 20}}>
                    {site_settings?.referral_settings?.length > 1 && <div style={{ display: "flex" }}>
                        {site_settings?.referral_settings?.map((item, i) => {
                            
                            let thisCount = i+1
                            let backg = (selectedLevel==thisCount)?systemColorManager.btn_2:systemColorManager.no_bg

                            return <div onClick={() => setSelectedLevel(thisCount)} key={i} className="flexify" style={{cursor: 'pointer', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, minWidth: 80, height: 80, 
                                ...backg, marginRight: 5, ...systemColorManager.borders_color, ...systemColorManager.txt_1}} >
                                <Images width={20} height={20} source={item[`image_${thisCount}`]} />
                                {parse(item[`name_for_level_${thisCount}`])}
                            </div>

                        })}
                    </div>}
                </div>*/}




                <div style={{marginTop: 20, ...systemColorManager.txt_1}}>
                    {parse(site_settings?.referral_settings?.[selectedLevel-1]?.[`note_for_level_${selectedLevel}`])}
                </div>

                


                <button style={{position: 'relative', marginTop: '180px', bottom: 10, ...systemColorManager.btn_1, ...systemColorManager.txt_3}} className="btn btn-info btn-block cursor-pointer" onClick={() => copyThis(myRefLink)}>Referral Code</button>

                    





                <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={invitationCodeModal} onClose={toggleInviteModal}>
                
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div className="" style={{maxHeight: 'auto', width: '90%'}}>

                            <div className="text-center" style={{padding: 1, paddingBottom: 20}}>
                                
                                <div style={{backgroundColor: '#fff', ...systemColorManager.btn_1, paddingTop: 6, paddingBottom: 0, display: 'inline-block'}}>
                                    <QRCode bgColor="#fff" value={myRefLink} />
                                </div>

                            </div>

                        </div>
                    </div>
                </DownModal>



        </SiteWide>
    );

}

export default RefLink;