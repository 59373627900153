import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {systemColor} from '../utility/styles';

import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import MobileBreaker from "./layouts/MobileBreaker";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, footballSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {footballActions, authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import Helper1, {byNextDate, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiMenuLine, RiCheckboxLine } from "react-icons/ri";
import { AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import Cookies from 'js-cookie'

import {FcBookmark, FcBriefcase, FcBrokenLink, FcUpload, FcBusinessContact, FcCableRelease, FcDiploma2} from "react-icons/fc";
// import Ticker from 'react-ticker'
import Marquee from 'react-css-marquee'

// import EachFixture from "./Football/EachFixture"

import BetModalPlace from './Football/BetModalPlace';
import parse from 'html-react-parser';


import { AiFillAlert, AiFillWechat, AiFillApi, AiFillAppstore, AiFillBook, AiFillBuild, AiFillBulb } from "react-icons/ai";

// import { HiOutlineChartPie, HiOutlineCalculator, HiOutlineCalendarDays, HiOutlineCalendar, HiOutlineCamera, HiOutlineChartBarSquare, HiOutlineChartBar } from "react-icons/hi2";

import CustomAgo from "./customComponents/CustomAgo";

import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'

import NewsTicker from "./customComponents/Ticker/NewsTicker";
import { PuffLoader } from 'react-spinners';
import SocialMediaIcons from './SocialMediaIcons';


const nextMatch = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}




function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const darkVal = (Cookies.get('darkMode') ?? 1)

    const [isDarkMode, setisDarkMode] = useState(darkVal) //dark mode by default

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData    = useSelector(allTradeSelect);
    const footballData      = useSelector(footballSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');

    const [recentBets, setRecentBets]   = useState([]);
    const [recentBetsLoading, setRecentBetsLoading]   = useState(true);


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [homeFixture, setHomeFixture]   = useState(byNextDate(footballData?.fixtures));

    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();

    const systemColorManager = systemColor()
    const [betModal, setBetModal]          = React.useState(false);
    const [selectedOddObj, setSelectedOddObj]          = React.useState({});


    const dispatch = useDispatch();

    const ref = useRef();


    useEffect(() => {
        setHomeFixture(byNextDate(footballData?.fixtures));
    }, [footballData?.fixtures])


    useEffect(() => {
        loadRecentBets()
    }, [])

    const loadRecentBets = () => {

        if((site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 0){ return; }

        setRecentBetsLoading(true)
        dispatch(footballActions.onGetRecents("football/home-recent", response => {

            setRecentBetsLoading(false)

        }))

    }


    const changeToDarkMode = (booleanVal) => {
        Cookies.set('darkMode', booleanVal, { secure: true, sameSite: 'Lax', expires: 7})
        setisDarkMode(booleanVal)
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])



    
    



    // main slider
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };



    const eachNewsClicked = (item) => {
        history.push(`/news?id=${item.id}`)
    }




    const eachIsClicked = (eachFixture) => {

        if(!userData?.accountId){
            history.push(`signin`)
            return;
        }
        setSelectedOddObj(eachFixture)
        setBetModal(true)
    }



    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
        notify('You are logged out', 1);
    }


    const takeToSupport = () => {
        window.open(site_settings?.site_settings?.telegram, '_blank');
    }


    const sideMenu = [
        {title: 'Home', url: '/', image: 'manu-1.png', use: true},
        {title: 'Deposit', url: '/deposit', image: 'manu-2.png', use: true},
        {title: 'Withdraw', url: '/withdraw', image: 'manu-3.png', use: true},
        
        {title: 'Games', url: '/game', image: 'manu-5.png', use: true},
        {title: 'My Bets', url: '/games/mine', image: 'manu-5.png', use: true},

        {title: 'Search Results', url: '/fixture/result', image: 'manu-16.png', use: false},
        
        {title: 'Deposits', url: '/deposit-records', image: 'manu-6.png', use: true},
        {title: 'Withdrawals', url: '/withdrawal-records', image: 'manu-7.png', use: true},

        {title: 'News', url: '/news', image: 'manu-8.png', use: ((site_settings?.operational_settings?.ticker_news_show_menu ?? 0) == 1)},
        {title: 'About', url: '/about', image: 'manu-9.png', use: ((site_settings?.content_settings?.[0]?.con1 ?? '') != '')},
        {title: 'FAQ', url: '/about', image: 'manu-10.png', use: ((site_settings?.faq?.length ?? 0) != 0)},
        {title: 'Privacy', url: '/privacy-policy', image: 'manu-11.png', use: false},
        {title: 'Terms', url: '/term', image: 'manu-12.png', use: false},

        {title: 'Profile', url: '/personal-information', image: 'manu-13.png', use: true},
        {title: 'Team', url: '/my-team', image: 'manu-17.png', use: true},

        {title: 'Log out', action: doLogOutNowNow, image: 'manu-14.png', use: userData?.username},
        {title: 'Log in', url: '/signin', image: 'manu-15.png', use: !userData?.username},
        {title: 'Sign up', url: '/signup', image: 'manu-16.png', use: !userData?.username},
        {title: 'Support', action: takeToSupport, image: 'manu-8.png', use: true},

    ]

    const otherMenu = (item) => {
        if(item.action){
            let definedMethod = item.action?.()
            return;
        }

        history.push(item.url)
    }

    const MenuContent = ({item}) => {
        return <div className="col-3 col-md-3 cursor-pointer" style={{padding: 10, marginBottom: 15, marginTop: 15}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} onClick={() => otherMenu(item)}>
                <Images width={'80%'} source={require(`../images/dashboard-icons/${item.image}`)} />
                <div style={{marginTop: 10, fontSize: 10, color: '#fff', fontWeight: 900}}>
                    {item.title}
                </div>
            </div>
        </div>

    }


    return (

        <SiteWide overlayText={loadingText} loading={loading}>                



               <div className="parent-container">
                  

                  <WebLink to={'/withdraw'} className="child-element cursor-pointer" style={{padding: 10, position: 'relative'}}>
                    <h4 className="text-whte prem" style={{color: '#070044', zIndex: 90, position: 'relative'}}>Balance</h4>
                    <small style={{zIndex: 90, position: 'relative'}}>{finance?.[0]?.type?money(finance?.[0]?.balance, finance?.[0]?.type) : '0.00'}</small>
                    <Images style={{width: 60, marginBottom: 30, position: 'absolute', right: 5, top: 4, zIndex: 8}} src={require('../images/dashboard-icons/walleta.png')} />
                  </WebLink>


                  <WebLink to={'/game'} className="child-element cursor-pointer" style={{padding: 10, position: 'relative'}}>
                    <h4 className="text-whte prem" style={{color: '#070044', zIndex: 90, position: 'relative'}}>Fixture</h4>
                    <small>Hot Games</small>
                    <Images style={{width: 60, marginBottom: 30, position: 'absolute', right: 5, top: 4, zIndex: 8}} src={require('../images/dashboard-icons/tvgybhcuop.png')} />
                  </WebLink>

                  <WebLink to={'/invite-friends'} className="child-element cursor-pointer" style={{padding: 10, position: 'relative'}}>
                    <h4 className="text-whte prem" style={{color: '#070044', zIndex: 9}}>Invite</h4>
                    <small>Friend</small>
                    <Images style={{width: 60, marginBottom: 30, position: 'absolute', right: 5, top: 4, zIndex: 8}} src={require('../images/dashboard-icons/invitation-letter.png')} />
                  </WebLink>

                </div>


                <div className="row no-gutter" style={{margin: 0}}>

                    <div className="col-12">
                        <Slider className="main-home-slider" arrows={false} {...settings}>
                            {site_settings?.slider_settings?.map((item, index) => (
                                <div style={{}} className="">
                                    <a target="_blank" href={item?.linkWhenClicked}>
                                        <Images style={{}} resizeMode="contain" height="200" width="100%" source={item?.image} />
                                    </a>
                                </div>)
                            )}
                        </Slider>
                    </div>
                    {/*<div className="col-3">
                        <Images style={{borderRadius: 8, marginLeft: 8}} resizeMode="contain" height="200" width="100%" source={require('../images/8856a07e6068bcc9f920b5e05358bb26.png')} />
                    </div>*/}

                </div>



                {site_settings?.operational_settings?.ticker_news_on_home_allowed == 1 && 1>2 && <NewsTicker eachNewsClicked = {eachNewsClicked} title={site_settings?.operational_settings?.ticker_news_home_title} newsList={site_settings.news}/>}





                <div className="row" style={{marginLeft: 0, marginRight: 0, borderRadius: 10, marginTop: 20, ...systemColorManager.bg_1}}>
                    {sideMenu?.filter(item => item.use).slice(0, 8).map((item, index) => <MenuContent item={item} />)}
                </div>

                <div className="row" style={{marginLeft: 0, marginRight: 0, borderRadius: 10, marginTop: 20, ...systemColorManager.bg_1}}>
                    {sideMenu?.filter(item => item.use).slice(8, 16).map((item, index) => <MenuContent item={item} />)}
                </div>

                <div className="row" style={{marginLeft: 0, marginRight: 0, borderRadius: 10, marginTop: 20, ...systemColorManager.bg_1}}>
                    {sideMenu?.filter(item => item.use).slice(16, 24).map((item, index) => <MenuContent item={item} />)}
                </div>










                    {(site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 1 && <div style={{marginTop: 18}}>


                        <div className="flexify" style={{marginTop: 18, marginBottom: 18}}>

                            <div className="flexify-left" style={{...systemColorManager.txt_1, flex: 1, marginTop: 15, alignItems: 'center'}}>
                                <FaStar size={12} /> &nbsp; Recent games
                            </div>

                        </div>


                        <div className="" style={{borderRadius: 10, ...systemColorManager.bg_2}}>

                            <div className="flexify" style={{padding: 20, borderTopLeftRadius: 10}}>
                                
                                <div className="text-center" style={{...systemColorManager.txt_1, flex: 1}}>
                                   Match
                                </div>

                                <div className="text-center" style={{...systemColorManager.txt_1, flex: 1}}>
                                   Amount
                                </div>

                            </div>

                            <div  style={{...systemColorManager.bg_2}}>
                                {footballData?.recent_bets?.map((item) => <div className="flexify" style={{...systemColorManager.txt_1, padding: 20, borderTopLeftRadius: 10}}>
                                    <div className="text-cente" style={{flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start'}}>{`${item.teams_home_name} vs ${item.teams_away_name}`}</div>
                                    <div className="text-cente" style={{}}>{money(item.amount, item.currency)}</div>
                                </div>)}
                            </div>


                        </div>

                    </div>}
                      


                    <a target="_blank" href={site_settings?.site_settings?.telegram}>
                        <Images style={{borderRadius: 10}} source={site_settings.site_settings?.livechat_image_banner} alt="Live chat" />
                    </a>









                    {(site_settings?.site_settings?.showAccreditation ?? 0) == 1 && 1>2 && <div className="flexify" style={{marginTop: 30, padding: 5, paddingBottom: 20, ...systemColorManager.txt_3}}>
                        <div className="text-center" style={{flex: 1}}>Programmed by <a href="mailto:hyiptonya@protonmail.com">Tonya Republical</a></div>
                    </div>}

                    <div className="flexify" style={{marginTop: 5, padding: 5, paddingBottom: 5, ...systemColorManager.txt_3}}>
                        <div className="prem text-center" style={{flex: 1}}>Copyright &copy; {(new Date).getFullYear()} </div>
                    </div>


        </SiteWide>
    );
}

export default Home;