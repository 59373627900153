import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";

import {systemColor} from '../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0
import LoadingOverlay from 'react-loading-overlay';


import { MdVerifiedUser, MdOutlineVpnLock, MdGroupWork, MdLockClock, MdArrowRightAlt } from "react-icons/md";


function PersonalInformation(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [uploadingAvatar, setUploadingAvatar]           = useState(false);
    const hiddenFileInput = React.useRef(null);

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);

    
    const systemColorManager = systemColor()

    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }



    React.useEffect(() => {

        loadAllHomePageData();
        

    }, [])



    const dashMenu = [
        
        {
            icon: MdVerifiedUser,
            name: 'Account Nickname',
            color: '#392',
            value: userData.username,
            show: true,
        },
        {
            icon: MdGroupWork,
            name: 'Email ID',
            color: '#392',
            value: userData.email,
            show: true,
        },
        {
            icon: MdOutlineVpnLock,
            name: 'User Country',
            color: '#392',
            value: userData.countryName,
            show: true,
        },

        {
            icon: MdLockClock,
            name: 'Security Center',
            color: '#392',
            to: () => history.push('/security-center'),
            show: true 
        },

        {
            icon: MdLockClock,
            name: 'Update wallet',
            color: '#392',
            to: () => history.push('/wallet'),
            show: true 
        }
        


        
        
    ]




    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }


    const uploadAvatar = async (file) => {

        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )
  
    }

 


    return (
        <SiteWide id="main" title={`Information`}>

            <div className="transaction-details">
                


            <div class="container mt-5 mb-5">
            <div class="row no-gutters">
                <div class="col-md-4 col-lg-4 text-center" style={{backgroundColor: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <LoadingOverlay
                        active={uploadingAvatar}
                        spinner
                    >
                        <Images style={{width: '80%', borderRadius: '50%'}} id="avatarPlace" title="Click to upload" className="cursor-pointer im" onClick={progClickFileUpload} source={((userData?.image ?? null) != null)?userData?.image:require('../images/athlete1.png')} alt="avatar" />
                        <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>

                    </LoadingOverlay>
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="d-flex flex-column">
                        
                        <div class="p-3 bg-black text-white">
                            <h6 style={{color: '#fff'}}>{userData.username}</h6>
                            <small style={{color: '#fff'}}>{userData.email}</small>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row no-gutters">



                            <WebLink to={"/change-password"} class="col-12 bg-primary text-center skill-block">
                                <h6 style={{color: '#fff'}}>Update Password</h6>
                            </WebLink>

                            <WebLink to={"/change-pin"} class="col-12 bg-success text-center skill-block">
                                <h6 style={{color: '#fff'}}>Manage PIN</h6>
                            </WebLink>

                            <WebLink to={"/wallet"} class="col-12 bg-warning text-center skill-block">
                                <h6 style={{color: '#fff'}}>Wallet Address</h6>
                            </WebLink>
                            


            </div>
        </div>





            </div>

        </SiteWide>
    );

}

export default PersonalInformation;
