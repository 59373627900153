import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';
import CustomAgo from "../customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "../customComponents";

import {RiUser4Line } from "react-icons/ri";
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import {systemColor} from '../../utility/styles';
import './team.css';



function Referrals() {    

    const systemColorManager = systemColor()


    const [loadingText, setLoadingText]   = useState('Loading team...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [activeClass, setActiveClass]    = useState(1)

    const [mainUplineData, setMainUplineData]    = useState({})

    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(1)
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])

    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoading(false)

            setRefDataRaw(response?.data)
            setRefData(response?.data?.referrals)
            setMainUplineData(response?.data?.upline)

        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])


    const showReferral = (site_settings?.operational_settings?.useReferralBonus ?? 0) == 1
    const showRebate = (site_settings?.operational_settings?.useRebateBonus ?? 0) == 1

    const userData = useSelector(userSelect);


    const affiliatePlace = ({ currentItems }) => {
        return <>

                {currentItems?.map((eachdownline, index) => {

                    let refWithdrawnFromMe = eachdownline?.['refWithdrawnFromMe_' + viewingLevel];
                    let rebWithdrawnFromMe = eachdownline?.['rebateWithdrawnFromMe_' + viewingLevel];

                return <article class="cta">
                        <div class="cta__text-column">
                            <a>{eachdownline?.username}</a>
                            <h2>{niceDate(eachdownline?.date)}</h2>
                            <p style={{color: '#000'}}>
                                {refWithdrawnFromMe?`${money(refWithdrawnFromMe + rebWithdrawnFromMe)} earned`:''}
                            </p>
                        </div>
                    </article>})}
              </>
    }


    const changeTab = (newTab) => {
        setActiveClass(newTab)
    }


    const totalPlaces = [
                            {title: 'Logins',               count: refDataRaw?.['loginCount'] ?? 0}, 
                            {title: 'Bets Capital',   count: refDataRaw?.['betEarningAmount'] ?? 0}, 
                            {title: 'Bets Earning',           count: refDataRaw?.['betAmount'] ?? 0}, 
                            {title: 'Total Deposits',       count: refDataRaw?.['totalDeposits'] ?? 0},
                            {title: 'Total Withdrawals',    count: refDataRaw?.['totalWithdrawals'] ?? 0},
                        ]

    return (<SiteWide overlayBg loading={loading} overlayText={loadingText} title="Team">
        
            <div className="">
                


                <div class="tabs">
                    <div className="tabs-body">
                    
                       

                        <div className="tabs-content" style={{zIndex: '99', position: 'relative'}}>
                            


                            <div  className={'tabs-textItem ' + (activeClass === 1 ? 'show' : '')} >
                                


              




                                <div className="" style={{marginTop: 30, paddingLeft: 20, display: 'flex', justifyContent: 'center'}}>

                                    {site_settings?.referral_settings?.map((each, index) => {

                                        let item = index + 1
                                        let isActiveHere = viewingLevel == item?systemColorManager.btn_1:systemColorManager.btn_2

                                        return <>
                                            <button onClick={() => setViewingLevel(item)} style={{marginRight: 2}} className={`crazy-btn`}>Level {item}</button>
                                        </>})}
                   
                                </div>


                                <div className="" style={{marginTop: 60, padding: 0}}>
                                    <div className="row">
                                                                
                                        {(refData?.['level'+viewingLevel]?.length ?? []) > 0 && <PaginatedItems Template={affiliatePlace} arrayOfDataToDisplay={refData['level'+viewingLevel]} itemsPerPage={20} />}

                                    </div>
                                </div>






                            </div>

                        </div>

                    </div>
                </div>









                

                        
                    
            </div>

        </SiteWide>
    );

}

export default Referrals;