import React, {useState, useEffect} from "react";
import {Images} from "./index";


function NoData({type, title}) {

    return <div className="text-center" style={{marginTop: 50}}>
            <Images height={200} src={require('../../images/info.png')} />
            <div>
                <h2 className="prem text-dark text-center">{title ?? 'Empty'}</h2>
            </div>
        </div>

}

export default NoData;