import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";

import {mainStyle, mainColor, header, footer, otherColors, systemColor} from '../../utility/styles';
import { useLocation } from 'react-router-dom';
import NoData from './NoData';



function TransactionHolder(props) {

    const { pathname } = useLocation();
    const systemColorManager = systemColor()


    return (
        <div class=""> 

            

            {(props?.data?.length ?? 0) > 0 ? props.children: <NoData />}

        </div>
    );

}

export default TransactionHolder;