import React, {useState, useEffect} from "react";

import DownModal from "../layouts/DownModal";

import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';

import SelectCurrency from "../customComponents/SelectCurrency";


import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Images} from "../customComponents";
import { AiOutlineWallet } from "react-icons/ai";
import {systemColor} from '../../utility/styles';
import  './style.css';

import PinAuth from "../customComponents/PinAuth";
import { useLocation } from 'react-router-dom';

    




function Wallet({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [googleAuth, setGoogleAuth]   = useState('');

    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    
    
    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');
    const [iban, setIban]       = useState(userData?.iban ?? '');


    const [usdttrc20wallet, usdttrc20walletSet]       = useState( '');
    const [btcwallet, btcwalletSet]       = useState('');
    const [bchwallet, bchwalletSet]       = useState('');
    const [ethwallet, ethwalletSet]       = useState('');
    const [ltcwallet, ltcwalletSet]       = useState('');
    const [usdcwallet, usdcwalletSet]       = useState('');
    const [xrpwallet, xrpwalletSet]       = useState('');
    const [adawallet, adawalletSet]       = useState('');
    const [dogewallet, dogewalletSet]       = useState('');
    const [dashwallet, dashwalletSet]       = useState('');
    const [xtzwallet, xtzwalletSet]       = useState('');
    const [dotwallet, dotwalletSet]       = useState('');
    const [linkwallet, linkwalletSet]       = useState('');
    const [xlmwallet, xlmwalletSet]       = useState('');
    const [etcwallet, etcwalletSet]       = useState('');
    const [uniwallet, uniwalletSet]       = useState('');
    const [pmwallet, pmwalletSet]       = useState('');
    const [bnbwallet, bnbwalletSet]       = useState('');

    const [securityObj, setSecurityObj] = useState({})
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const { pathname } = useLocation();







    // determine the page we land on
    const isItBankPlace = pathname == '/bank'?true:false






    const [editPanel, setEditPanel]   = useState('');


    const systemColorManager = systemColor()

    const dispatch = useDispatch();

    
    const toggleEditPanel = () => {
        setEditPanel(!editPanel)
    }

    const doAddressChange = (e) => {
        setloading(true);
        e.preventDefault();

        let updateObject = {...(securityObj ?? {}), usdttrc20wallet, btcwallet, bchwallet, ethwallet, ltcwallet, usdcwallet, xrpwallet, adawallet, dogewallet, dashwallet, xtzwallet, dotwallet, linkwallet, xlmwallet, etcwallet, uniwallet, pmwallet, bnbwallet}

        dispatch(authActions.onLogin(`account/account_change/1`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){
                toggleEditPanel()
            }
        }),
        );
    }


    const doBankUpdate = (e) => {
        
        e.preventDefault();
        setloading(true);

        let updateObject;

        updateObject = {iban, bank, accountName, accountNumber, ...(securityObj ?? {}),}

        dispatch(authActions.onLogin(`account/account_change/0`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){
                toggleEditPanel()
            }
        }),
        );
    }





    const updateCurrencySelected = (newCurObj) => {
        setCurrencySelected(newCurObj)
    }

    const toggleCurrencyModal = () => {
        setCurrencyModal(!currencyModal)
    }



    useEffect(() => {
       let mat =  userData?.bankdetails?.map((item, detail) => eval(item?.valueJsConstFunction)(item.value));
    }, [])

    const walletDetails = userData?.bankdetails?.filter((eachWallet, theIndex) => eachWallet.abbr == currencySelected.abbr)


    const showAccountName = accountName == ''?'Account Name':accountName
    const showAccountNumber = accountNumber == ''?'XXXXXXXXXXXXXXX':accountNumber
    const showIban = iban == ''?'IBAN':iban

    return (
        <SiteWide title={isItBankPlace?`Bank manager`:`Wallet manager`}>
                









            <div class="card" style={{width: '100%', height: '220px'}}>
                <div class="card__info">
                    <div class="card__logo">{site_settings?.site_settings?.sitename}</div>
                    <div class="card__chip">
                        <svg class="card__chip-lines" role="img" width="20px" height="20px" viewBox="0 0 100 100" aria-label="Chip">
                            <g opacity="0.8">
                                <polyline points="0,50 35,50" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="0,20 20,20 35,35" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="50,0 50,35" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="65,35 80,20 100,20" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="100,50 65,50" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="35,35 65,35 65,65 35,65 35,35" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="0,80 20,80 35,65" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="50,100 50,65" fill="none" stroke="#000" stroke-width="2" />
                                <polyline points="65,65 80,80 100,80" fill="none" stroke="#000" stroke-width="2" />
                            </g>
                        </svg>
                        <div class="card__chip-texture"></div>
                    </div>
                    <div class="card__type">debit</div>
                    <div class="card__number">
                        <span class="text-center card__digit-group">{isItBankPlace?showAccountNumber:(walletDetails?.[0]?.value ?? '' !='') ?walletDetails?.[0]?.value: '0000000000000000'}</span>
                    </div>
                    <div class="card__valid-thru" aria-label="Valid thru">Valid<br />thru</div>
                    <div class="card__exp-date"><time datetime="2038-01">01/38</time></div>
                    <div class="card__name" aria-label="Dee Stroyer">{userData.firstname} {userData.surname}</div>
                    <div class="card__vendor" role="img" aria-labelledby="card-vendor">
                        <span id="card-vendor" class="card__vendor-sr">Mastercard</span>
                    </div>
                </div>
                <div class="card__texture"></div>
            </div>
                            









                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="" style={{maxHeight: 'auto', width: '100%'}}>

                        


                        {!isItBankPlace && <form onSubmit={doAddressChange}>

                            <div>

                                <div>
                                    {userData?.bankdetails?.filter((eachItem) => (eachItem.abbr == currencySelected.abbr))?.map((item, detail) => <div className="single-input-wrap">

                                        <span className="new-input-label" style={{...systemColorManager.txt_1}}>{item?.abbr} wallet</span>
                                        <input value={eval(item.valueJsConst)} placeholder={item?.name} onChange = {e => eval(item?.valueJsConstFunction)(e.target.value) } required="" className="form-field form-control" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}} type="text" />
                                    
                                    </div>)}
                                </div>

                                <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />

                            </div>

                            <div>
                                <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                                Save </button>
                            </div>
                            
                        </form>}







                        {isItBankPlace && <form onSubmit={doBankUpdate}>
                            
                            <div class="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Bank Name</span>
                                <input value={bank} placeholder="Bank" onChange = {e => setBank(e.target.value) } required="" className="form-field form-control" type="text" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}}/>
                            </div>

                            <div class="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Account Name</span>
                                <input value={accountName} placeholder="Account Name" onChange = {e => setAccountName(e.target.value) } required="" className="form-field form-control" type="text" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}}/>
                            </div>

                            <div class="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Account Number</span>
                                <input value={accountNumber} placeholder="Account Number" onChange = {e => setAccountNumber(e.target.value) } required="" className="form-field form-control" type="text" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}}/>
                            </div>

                            <div class="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>IBAN</span>
                                <input value={iban} placeholder="IBAN" onChange = {e => setIban(e.target.value) } required="" className="form-field form-control" type="text" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}}/>
                            </div>

                            <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />

                            <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                                Save
                            </button>

                        </form>}



                        <div style={{...systemColorManager.txt_1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <span style={{cursor: 'pointer', paddingTop: 4, paddingBottom: 4, }} onClick={toggleEditPanel}>Close</span>
                        </div>


                    </div>
                </div>




            <SelectCurrency selectedObj={currencySelected} curArrayToLoop={finance} isOpen={currencyModal} closeCurrencyModal={toggleCurrencyModal} onSelectCurrency={updateCurrencySelected} />



        </SiteWide>
    );

}

export default Wallet;