import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";

import {mainStyle, mainColor, header, footer, otherColors, systemColor} from '../../utility/styles';
import { useLocation } from 'react-router-dom';



function AuthHolder(props) {

    const { pathname } = useLocation();
    const systemColorManager = systemColor()


    return (
        <>

                <div className="" style={{marginTop: 60}}>
                    <FullScreenInputHolder>
                        <form className="" style={{borderRadius: 10}}>

                            <div style={{}}>
                                {props.children}
                            </div>

                        </form>
                    </FullScreenInputHolder>
                </div>

        </>
    );

}

export default AuthHolder;