import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {systemColor} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';
import Navbar from "./Navbar";

import MobileBreaker from "./MobileBreaker";

import {WebLink, SiteWide, Images} from "../customComponents";
import {useLocation} from "react-router-dom";



function Footer() {



        const { pathname } = useLocation();

        const systemColorManager = systemColor()

        const isPage = (item) => item.address == pathname || 
            (item.address == '/mine' && (pathname == '/signup' || pathname == '/signin' || pathname == '/reset-password'))

        return (
            <>

                





            </>
        );

}

export default Footer;