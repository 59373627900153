import React, {useState, useEffect} from "react";

import {notificationAction} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { notificationSelect } from '../../redux/selectors/index';
import { store } from "../../redux/store";
import {Images} from "./index";
import { RiCloseFill, RiCheckDoubleFill } from "react-icons/ri";
import { FcCancel, FcApproval } from "react-icons/fc";
import MobileBreaker from "../layouts/MobileBreaker";
import { AiFillCloseCircle } from "react-icons/ai";

 import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function Notify() {

    return <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />

}

export default Notify;