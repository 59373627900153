import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../utility/Helper1';

import {footballActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";

import {systemColor} from '../../utility/styles';
import { AiFillQuestionCircle, AiFillClockCircle, AiFillWallet } from "react-icons/ai";

import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";
import copy from 'copy-to-clipboard';

import DownModal from "../layouts/DownModal";




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}



function ActiveMatches() {

    const dispatch = useDispatch();

    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Fetching games...');
    const footballData                      = useSelector(footballSelect);

    const [loading, setloading]             = useState(true);
    
    const [userGames, setUserGames]   = useState(footballData?.user_games);

    const systemColorManager = systemColor()

    useEffect(() => {

        setUserGames(footballData?.user_games)

    }, [footballData?.user_games])

    useEffect(() => {

        fetchUserGames()

    }, [])


    const fetchUserGames = () => {
        dispatch(footballActions.onGetUserGames("football/user/loadbets", response => {

            setloading(false)

        }))
    }


    const copyFixtureId = (itemObj) => {
        copy(itemObj.api_fixture_id)
        notify('Fixture ID copied', 1)
    }

    const betInfo = (objItem) => {
        notify(`You ${objItem.loseOrWin == 0?'lose':'win'} ${objItem.teams_home_name} vs ${objItem.teams_away_name}. Your final reward is ${money(objItem?.finalReward, objItem?.currency)}`, objItem.loseOrWin)
    }


    const deleteGame = (objToDetete) => {

        dispatch(footballActions.onGetUserGames(`football/user/deleteBet/${objToDetete.id}`, response => {

            notify(response.msg, response.code)
            setloading(false)

        }))
    }


    const sortedGameTime = userGames?.sort((a, b) => new Date(b.time_played) - new Date(a.time_played));

    function Template({ currentItems }){

        return (
            <>

                {currentItems && currentItems.map((item) => {



                    // time of match
                    const dateString = item?.fixture_datetime;
                    const date = new Date(dateString);
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    const timeString = `${hours}:${minutes}`;
                    //time gotten


                    const day = date.getDate().toString().padStart(2, '0');
                    const month = date.toLocaleString('default', { month: 'long' });
                    const dayth = date.getDate();
                    const suffix = dayth === 1 || dayth === 21 || dayth === 31 ? 'st' : dayth === 2 || dayth === 22 ? 'nd' : dayth === 3 || dayth === 23 ? 'rd' : 'th';
                    const dateStringFormatted = `${day}${suffix} ${month}`;




                    return <div className="bbill-pay-inner"  style={{marginBottom: 15}}>
                    
                    <div className="text-center flexify">
                        <Images height={10} style={{}} source={item?.league_logo} alt={item?.league_name} />
                        <span style={{marginLeft: 5, ...systemColorManager.txt_2}}>{item?.league_name}</span>
                    </div>

                    <div className="" style={{borderRadius: 0, background: 'rgba(0, 0, 0, 0.8)'}}>

                        <div>

                             <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                                
                                <div className="flexify" style={{}}>
                                    

                                    <div className="cursor-pointer" style={{position: 'absolute', top: 0, right: 0, backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
                                        {(item?.refunded ?? '') == 1 || 1==1 && <small className="prem" style={{...systemColorManager.txt_1, padding: 5}}>Game Cancelled</small>}
                                    </div>

                                    <div className="text-center" style={{padding: 10, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>

                                        <div className="eague-img-circle" tyle={{width: 30, height:30, ...systemColorManager.btn_3}}>
                                            <Images height={20} style={{}} source={item?.teams_home_logo} alt={item?.teams_home_name} />
                                        </div>

                                        <div style={{...systemColorManager.txt_1, marginLeft: 5}}>{item?.teams_home_name}</div>

                                    </div>

                                    <div className="text-center bg-sucess" style={{padding: 10, background: '#fdb913'}}>
                                        
                                        <div style={{...systemColorManager.txt_2}}>
                                            <div style={{marginLeft: 4}} className={(item?.refunded ?? '') == 1?'banger':'badgge-info'}>{(item?.refunded ?? '') == 0?item.fixture_status_short:'CANCELLED'}</div>

                                            {money(item?.amount, item?.currency)}<br />
                                            {item?.['home-scores']}:{item?.['away-scores']}<br />
                                            {formatDate(item?.time_played)} {formatTime(item?.time_played)}<br />
                                            {(new Date(item.fixture_datetime) > new Date()) && <span onClick={() => window.confirm('Are you sure you want to delete this?') && deleteGame(item)} className="cursor-pointer text-danger">Delete</span>}
                                        </div>

                                    </div>

                                    <div className="text-center" style={{padding: 10, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>

                                        <div style={{...systemColorManager.txt_1, marginRight: 5}}>{item?.teams_away_name}</div>

                                        <div className="eague-img-circle" tyle={{width: 30, height:30,...systemColorManager.btn_3}}>
                                            <Images height={20} style={{}} source={item?.teams_away_logo} alt={item?.teams_away_name} />
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    {item?.awardedWinning == 1 && <div className="amount-inner" style={{borderTop: '0.1px dashed #eee'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h5 style={{...systemColorManager.txt_1}}>
                                {item?.refunded == 0?'Reward':'Refund'}
                            </h5>
                            <div className="flexify" style={{...systemColorManager.txt_1}}>

                                <AiFillQuestionCircle className="cursor-pointer" onClick={() => betInfo(item)} size={20} color={item?.loseOrWin == 1?'green':'red'}/>

                                <span style={{marginLeft: 5}}>{money(item?.refunded == 0?item?.finalReward:item?.refundAmount, item?.currency)}</span>
                            </div>

                        </div>
                    </div>}

                </div>})}


            </>)
        
    }



    return (

        <SiteWide loading={loading} overlayText={loadingText} title={'My Games'}>
            
            

            {(userGames?.length ?? 0) > 0 && <PaginatedItems Template={Template} arrayOfDataToDisplay={sortedGameTime} itemsPerPage={10} />}

            {(userGames?.length ?? 0) == 0 && <NoData type="bet game" />}


        </SiteWide>
    );
}

export default ActiveMatches;
