import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";
import {footballActions} from '../../redux/actions';

import {systemColor} from '../../utility/styles';
import { AiFillStar, AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
// import EachFixture from "./EachFixture"
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../layouts/DownModal";
import MobileBreaker from "../layouts/MobileBreaker";
import Modal from 'react-modal';
import './AllFixtures.css';




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}



function AllFixtures() {

    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Loading history...');
    const footballData                      = useSelector(footballSelect);
    const finance                           = useSelector(homeScreenStatSelect);

    const [loading, setloading]             = useState(false);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    const [searchInput, setSearchInput]             = useState('');

    
    const fullFootballData = byNextDate(footballData?.fixtures)
    const [homeFixture, setHomeFixture]   = useState(fullFootballData);
    const [leagueModal, setLeagueModal]   = useState(false);
        
    const [selectedFixture, setSelectedFixture]                           = React.useState(null);
    const [amount, setAmount]               = useState('');
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0] ?? null);








const [showDialog, setShowDialog] = React.useState(false);  const open = () => setShowDialog(true);  const close = () => setShowDialog(false);






    const history = useHistory();
    const systemColorManager = systemColor()

    const dispatch = useDispatch();


    const toggleLeaguePanel = () => {
        setLeagueModal(!leagueModal)
    }






    

    const fixtureIsClicked = (oddObj) => {

        let newContent = oddObj;

        if(selectedFixture?.fixture_id == oddObj.fixture_id){
            newContent = null
        }

        setSelectedFixture(newContent)
    }


    const oddIsClicked = (oddObj) => {
        setSelectedOddObj(oddObj)
    } 



    const showOdds = (arrayToUse) => {
        return arrayToUse?.map((item) => <div onClick = {() => oddIsClicked(item)} style={{borderRadius: 0, background: selectedOddObj?.id == item.id?'#90b203':'rgba(255, 255, 255, 0.9)', ...systemColorManager.txt_1, borderBottomWidth: 5, isplay: 'flex', padding: 4, marginBottom: 4, justifyContent: 'space-between'}} 
            className="cursor-pointer"
        >
            <div className="prem" style={{...systemColorManager.txt_2}}>{item.['home-scores']}:{item.['away-scores']}</div>
            <small className="prem" style={{...systemColorManager.txt_2}}>{Number(item?.['percentage-winning'])?.toFixed(1)}%</small>
        </div>)
    }


     const updateAmount = (e) => {
        if(isNaN(e.target.value)){ return; }
        setAmount(e.target.value) 
    }  


    const doBetRequest = (e) => {

        e.preventDefault()

        if(!selectedOddObj){ window.alert('Please select an odd'); return; }
        if(loading){ return;}

        setloading(true)
        setLoadingText('Please wait')


        dispatch(footballActions.onSubmitBet({amount, currency: currencySelected.type, betId: selectedOddObj?.id}, "football/bet", response => {

            if(response.code == 1){
                setAmount('')
                setSelectedFixture(null)
            }
            
            notify(response.msg, response.code)
            setloading(false)
            setLoadingText('')

            
        }))


    }


    function Template({ currentItems }){

        return (
            <>
                {currentItems && currentItems?.map((item) => {

                // time of match
                const dateString = item?.fixture_datetime;
                const date = new Date(dateString);
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const timeString = `${hours}:${minutes}`;
                //time gotten


                const day = date.getDate().toString().padStart(2, '0');
                const month = date.toLocaleString('default', { month: 'long' });
                const dayth = date.getDate();
                const suffix = dayth === 1 || dayth === 21 || dayth === 31 ? 'st' : dayth === 2 || dayth === 22 ? 'nd' : dayth === 3 || dayth === 23 ? 'rd' : 'th';
                const dateStringFormatted = `${day}${suffix} ${month}`;


                const arrayOfHomeWins = item?.exactScoreOdd?.filter(function(item, index) {
                      return Number(item['home-scores']) > Number(item['away-scores']);
                    });

                const arrayOfAwayWins = item?.exactScoreOdd?.filter(function(item, index) {
                      return Number(item['away-scores']) > Number(item['home-scores']);
                    });

                const arrayOfDraws = item?.exactScoreOdd?.filter(function(item, index) {
                      return Number(item['away-scores']) == Number(item['home-scores']);
                    });


                return <div className="cursor-pointer" style={{marginBottom: 15}}>
            

                    <div className="text-center flexify">
                        <Images height={10} style={{}} source={item?.league_logo} alt={item?.league_name} />
                        <span style={{marginLeft: 5, ...systemColorManager.txt_2}}>{item?.league_name}</span>
                    </div>

                    <div className="" style={{borderRadius: 0, background: 'rgba(0, 0, 0, 0.8)'}}>

                        <div onClick={
                                    () => fixtureIsClicked(item)
                                } >

                             <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                                
                                <div className="flexify" style={{}}>
                                    
                                    <div className="text-center" style={{padding: 10, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>

                                        <div className="eague-img-circle" tyle={{width: 30, height:30, ...systemColorManager.btn_3}}>
                                            <Images height={20} style={{}} source={item?.teams_home_logo} alt={item?.teams_home_name} />
                                        </div>

                                        <div style={{...systemColorManager.txt_1, marginLeft: 5}}>{item?.teams_home_name}</div>

                                    </div>

                                    <div className="text-center bg-sucess" style={{padding: 10, background: '#fdb913'}}>
                                        
                                        <div style={{...systemColorManager.txt_2}}>
                                            {timeString}<br />
                                            <small>{dateStringFormatted}</small>
                                        </div>

                                    </div>

                                    <div className="text-center" style={{padding: 10, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>

                                        <div style={{...systemColorManager.txt_1, marginRight: 5}}>{item?.teams_away_name}</div>

                                        <div className="eague-img-circle" tyle={{width: 30, height:30,...systemColorManager.btn_3}}>
                                            <Images height={20} style={{}} source={item?.teams_away_logo} alt={item?.teams_away_name} />
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                    {selectedFixture?.fixture_id == item?.fixture_id && <div className="" style={{borderRadius: 0, background: '#fdb913'}}>

                        <div>

                             <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                                
                                
                                <div className="flexify" style={{}}>
                                    
                                    <div className="text-center bg-sucess" style={{padding: 10, background: '#fdb913'}}>

                                        {showOdds(arrayOfHomeWins)}

                                    </div>

                                    <div className="text-center bg-sucess" style={{padding: 10, background: '#fdb913'}}>
                                        {showOdds(arrayOfDraws)}
                                    </div>

                                    <div className="text-center bg-sucess" style={{padding: 10, background: '#fdb913'}}>

                                        {showOdds(arrayOfAwayWins)}

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div> }

                    

                    

                </div>})}
            </>)
        
    }




    const newDataToShow = (newArray) => {
        toggleLeaguePanel()
        setHomeFixture(byNextDate([...newArray]))
    }

    const showAllLeague = (newArray) => {
        setLoadingText('Loading all leagues')
        setloading(true)
        setHomeFixture([...fullFootballData])

        setTimeout(() => {
            setLoadingText('')
            setloading(false)
        }, 2000)
    }




    const groupedData = fullFootballData?.reduce((acc, curr) => {
      const { league_name } = curr;
      if (!acc[league_name]) {
        acc[league_name] = [];
      }
      acc[league_name].push(curr);
      return acc;
    }, {});

    let leagues_name;

    if (groupedData && typeof groupedData === 'object') {
        leagues_name = Object.values(groupedData);
    } 

    let searchLocal = homeFixture?.filter(item => item.league_name.match(new RegExp(searchInput, "i")) || item.teams_home_name.match(new RegExp(searchInput, "i")) || item.teams_away_name.match(new RegExp(searchInput, "i")))




    return (

        <SiteWide loading={loading} overlayText={loadingText} title={'Games Market'}>
            


            <div class="">
               <div class="">

                  <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} style={{background: '#fff', marginBottom: 10, padding: 5, width: '100%', borderRadius: 40, border: '10px solid #eee'}} type="text" class="" placeholder="What are you looking for?" />
                  
               </div>
            </div>


            {(searchLocal?.length ?? 0) > 0 && <div className="text-center">   
                <Images style={{width: 200}} src={require('../../images/dashboard-icons/world-cup.png')} />
                <h3 className="prem" style={{fontSize: 40, ...systemColorManager.txt_2}}>
                    FIXTURES
                </h3>
            </div>}
            





            {(searchLocal?.length ?? 0) == 0 && <NoData title={"Oops... No fixture to display"} />}

            {(searchLocal?.length ?? 0) > 0 && <PaginatedItems Template={Template} arrayOfDataToDisplay={searchLocal} itemsPerPage={10} />}



            {selectedOddObj && <div className="DialogueBox">

             <div className="CloseIcon" onClick={() => setSelectedOddObj(null)}>
                X
              </div>

              <h2 className="text-white">Bet Amount</h2>

              <p className="text-white">Please specify the bet amount below.</p>
              <div style={{maxHeight: 'auto', paddingRight: 0, paddingLeft: 0, paddingBottom: 0}}>
                    <form onSubmit={doBetRequest} style={{marginTop: 20, width: '100%'}}>

                        <div className="text-left text-white" style={{color: '#fff', display: 'flex', lex: 1, flexDirection: 'column', alignItems: 'flex-start', padding: 10}}>
                            <h6 className="text-white">Profitability Score</h6><br />
                            ${(amount * (Number(selectedOddObj?.['percentage-winning'] ?? 0)/100)).toFixed(2)}
                        </div>

                        <div class="ingle-input-wrap" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            
                            <input required placeholder="Input Bet Amount" required="" onChange={updateAmount} className="form-field form-control" type="text" style={{color: '#000', flex: 3, borderRadius: 0, padding: 10, background: '#e91e79', border: 'none'}}/>


                        </div>

                        <button type="submit" className="btn btn-block tn-danger" style={{marginTop: 10, border: 'none', background: '#000', color: '#fff'}}>
                            Submit Bet
                        </button>

                    </form>
                </div>
            </div>}


        </SiteWide>
    );
}

export default AllFixtures;